
















import {Component, Vue, Inject, Prop, Watch} from "vue-property-decorator";
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Contact from '../../../../store/entities/companycontext/contact'
import ContactForm from './contact-form.vue'
import CompanyContact from "@/views/setting/companycontext/companycontact/CompanyContact.vue";

@Component({

    components: {ContactForm, CompanyContact}
})
export default class EditContact extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    isMounted: boolean = false;
    contact: Contact = new Contact();
    contactId: number;

    async created() {
        this.contactId = parseInt(this.$route.params["contactId"]);
        this.isMounted = true;
    }

    save() {
        let contactForm = ((this.$refs.baseForm as any).$refs.contactForm as any);
        contactForm.validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: 'contact/update',
                    data: (this.$refs.baseForm as any).getContact() as Contact
                });
                (contactForm).resetFields();
                this.$router.back();
            }
        })
    }

    cancel() {
        ((this.$refs.baseForm as any).$refs.contactForm as any).resetFields();
        this.$emit('input', false);
        this.$router.back();
    }
}
